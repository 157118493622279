import React from "react"
import Text from "../../components/text"
import Button from "../../components/button"
import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Hero = ({ HeroData }) => {
    const { heroType, content, heroBackgroundImage, heroBackgroundGradient, theme } = HeroData
    const heroButtons = content?.buttons
    const contentGraphic = content?.contentGraphic

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <p
                        className= 
                            {`text-ProjectBlack font-workSans font-normal leading-[150%] max-w-[514px] mt-4 text-left text-base translate-y-[10px] ${
                                    heroType[0] === "Primary"
                                    ? " md:text-[21px]"
                                    : " md:text-[19px]"
                                }`
                            }
                        data-sal="slide-up"
                        data-sal-duration="1200"
                        data-sal-delay="150"
                        data-sal-easing="ease-animationSection"
                    >
                        {children}
                    </p>
                )
            },
        },
    }

    return (
        <div className={`min-h-[500px] md:h-[774px] w-screen relative md:-mt-[90px] ${heroBackgroundGradient === true ? "" : "" }`}>
            <div 
                className={`absolute top-0 w-screen h-full -z-20 
                    ${heroBackgroundGradient === true ? "bg-gradient" : " bg-black" 
                }`} 
            >
                {heroBackgroundImage && (
                    <img
                        src={heroBackgroundImage.file.url}
                        alt={heroBackgroundImage.file.title}
                        // className="object-cover w-screen min-h-[500px] md:min-h-[1002px]"
                        className="object-cover w-screen h-full"
                    />
                )}
            </div>
            <div className="min-h-[500px] md:h-[850px] w-full flex items-center justify-start px-5 md:px-10 xl1:px-40 -ml-1 py-10">
                <div
                    className={`w-full max-w-screen-xl pt-[50px] mx-auto text-left ${heroType[0] === "Split Graphic"
                        ? "flex items-center justify-between flex-col md:flex-row"
                        : ""
                        }`}
                >
                    <div className={heroType[0] === "Split Graphic" ? "md:w-1/2" : "md:w-full"}>
                        <p className="text-base font-medium tracking-widest uppercase text-ProjectBlue Figtree-Medium  pb-5 translate-y-[10px]"
                            data-sal="slide-up"
                            data-sal-duration="1200"
                            data-sal-delay="0"
                            data-sal-easing="ease-animationSection"
                        >
                            {content.contentBlockTagline}
                        </p>
                        <h1
                            className=
                            {`text-[28px] text-left 
                                ${heroType[0] === "Primary" 
                                    ? "md:text-[62px] md:max-w-[747px]" 
                                    : heroType[0] === "Secondary" 
                                    ? "md:text-[30px] xl:text-[56px] md:max-w-[747px]" 
                                    : "md:text-[30px] xl:text-[44px] md:max-w-[538px]"
                                } 
                                tracking-atswide uppercase text-ProjectBlack Saira-Bold not-italic leading-[120%] font-extrabold translate-y-[10px]`
                            }
                            data-sal="slide-up"
                            data-sal-duration="1200"
                            data-sal-delay="0"
                            data-sal-easing="ease-animationSection">
                            {content.contentBlockTitle}
                        </h1>
                        {renderRichText(content.contentBlockDescription, options)}
                        <div className="flex items-center justify-start md:max-w-xs translate-y-[10px]"
                            data-sal="slide-up"
                            data-sal-duration="1200"
                            data-sal-delay="300"
                            data-sal-easing="ease-animationSection"
                        >
                            <div className="flex">
                                {heroButtons && heroButtons.map((buttonData, index) => (
                                    <div key = {`heroButton-${index}`} className={index === heroButtons.length - 1 && index > 0 ? "ml-[1.25rem] mt-[1.875rem] lg:mt-[2.5rem] " : "mt-[1.875rem] lg:mt-[2.5rem] "}>
                                        <Button
                                            link={buttonData?.url}
                                            title={buttonData.title}
                                            variant={buttonData.elementType === "primaryButton" ? "primary" : "secondary"}
                                            theme={buttonData.theme?.[0].toLowerCase()}
                                        />
                                    </div>
                                ))}
                                {content.actionText && content.actionUrl && (
                                    <Text 
                                        link = { content.actionUrl }
                                        title = { content.actionText}
                                        theme= {theme?.[0].toLowerCase()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${heroType[0] === "Split Graphic" ? "max-w-1/2 py-[67px] md:py-0 " : "hidden"
                        }`}
                    >
                    {contentGraphic?.map((item, index)=>(
                        <img
                            src={item.image.file.url}
                            alt={item.image.title}
                            className="max-w-[458px] w-full min-h-[374px]  mx-auto"
                            key={`contentGraphic-${index}`}
                        />
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

Hero.propTypes = {
    variant: PropTypes.string,
}

export default Hero
