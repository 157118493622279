import React from "react"
import ProductCard from "../../components/product-card"

const Section = (sectionData) => {
    
    return (
        <>
            {Object.values(sectionData).map((item,index) => {
                return (
                    <ProductCard 
                        alignment={item?.alignment?.[0].toLowerCase()}
                        theme={item?.theme?.[0].toLowerCase()}
                        content={item?.contentBlocks}
                        key={`product-card-${index}`}
                    />
                )
            }
            )}
        </>
    )
}
export default Section