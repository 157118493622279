import * as React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import cookieContext from '../../contexts/cookieProvider';
import Cookies from "js-cookie";

const CookieConsent = () => {
  const location = useLocation()
  const {bannerHidden, setBannerHidden} = React.useContext(cookieContext)
  React.useEffect(() => {
    initializeAndTrack(location)
  }, [location])


  const isBrowser = () => {
    return typeof window !== 'undefined';
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    let cookieCount = 0
    for (let i = 0; i < name.length; i++) {
      const tag = name[i];
      const parts = value.split(`; ${tag}=`);
      if (parts.length === 2) cookieCount++;
    }
    return cookieCount === 2;
  }


  const getValue = (key, defaultValue) => {
    return isBrowser() && !getCookie(key)
      ? true
      : defaultValue;
  }

  const useStickyState = (defaultValue, key) => {
    const [value, setter] = React.useState(() => {
      return getValue(key, defaultValue);
    });

    return [value, setter];
  }
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  // const [bannerHidden, setBannerHidden] = useStickyState(
  //   false,
  //   ['gatsby-gdpr-google-tagmanager', 'gatsby-gdpr-linked-in']
  // );

  const EnableAnalytics = (status) => {
    const now = new Date();
    const time = now.getTime();
    const days = 400;
    const expireTime = time + days*24*60*60*1000;
    now.setTime(expireTime);
    document.cookie = `gatsby-gdpr-google-tagmanager=${status};expires=${now}.toUTCSTtring()`;
    document.cookie = `gatsby-gdpr-google-analytics=${status};expires=${now}.toUTCSTtring()`;
    document.cookie = `gatsby-gdpr-linked-in=${status};expires=${now}.toUTCSTtring()`;
    document.cookie = `gatsby-gdpr-google-analytics=${status};expires=${now}.toUTCSTtring()`;
    setBannerHidden(true);
  };
  return (
    <>
      {isBrowser() && !bannerHidden && (
        <div className=" fixed w-screen flex md:right-[40px] md:w-[781px] md:h-[90px] px-5 md:px-0 bottom-10 z-50 ">
          <div className="w-full flex items-center justify-between bg-[#D9D9D9] rounded-[10px] px-5">
            <span className=" text-dark font-workSans text-[17px] font-normal leading-6 py-2">
              By continuing to use this website you consent to our use of cookies.
            </span>
            <button className="text-xs md:text-sm text-white uppercase bg-black rounded-sm w-[100px] px-2 h-[30px] md:w-[150px] md:h-[50px] Saira-Bold tracking-atswide" onClick={() => EnableAnalytics(true)}> Accept </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;