import React from "react"
import { Link } from "gatsby"

// theme  => 'dark', 'grey', 'light' 

const Text = ({ link, title, theme }) => {
  return (
    <>
      {title && (
        <Link
          to={`${link}`}
          className={`basis-full pt-[20px] items-center justify-start h-[3.125rem] font-medium text-center leading-7 hover:underline rounded-sm font-workSans min-w-[150px] text-base md:text-[19px] next text-TextPrimaryBlue ${ theme === "dark"
              ? "  transition duration-300 ease-in-out"
              : ""
            }${ theme === "grey"
              ? " transition duration-300 ease-in-out"
              : ""
            }${ theme === "light"
              ? " transition duration-300 ease-in-out"
              : ""
            }`}
        >
          {title}
          <span className="arrow">&#10140;</span>
        </Link>
      )}
    </>

  )
}

export default Text
