import React, { useState, useRef } from "react"
import Text from "../text"
import Button from "../button"
import PropTypes from "prop-types"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"

import { EffectFade } from "swiper"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const ProductCard = ({ theme, alignment, content }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const [contentIndex, setContentIndex] = useState(0)
  const swiperRef = useRef(null)
  const sectionButtons = content[contentIndex]?.buttons
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p
            className={`pt-[10px] text-sm md:text-[17px] leading-6 font-workSans font-normal 
              text-${theme === "dark" ? "white" : "dark"} 
              ${sectionButtons || (content[contentIndex].actionText && content[contentIndex].actionUrl) ? "pb-[20px] md:pb-[30px]": ""}
            `}
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="100"
            data-sal-easing="ease-animationSection"
          >
            {children}
          </p>
        )
      }
    },
    renderText: text => text.split('\n').flatMap((text, index) => 
      [index > 0 && <br />, text]
    ),
  }

  return (
    <div
      className={`w-screen mx-auto bg-${theme === "light" ? "white" : theme} min-h-0 lg:min-h-[620px] h-full px-5 md:px-10 xl1:px-40 flex items-center justify-center flex-col md:py-0 `}
    >
      {content && content?.length > 1 && (
        <div
          className="flex items-center justify-center w-full mx-auto my-10 md:m-0"
        >
          {content?.map((tab, index) => (
            <>
              <button
                className={`tabs_btn min-h-[70px] md:min-h-[46px] Figtree-Medium font-medium text-[11px] md:text-xs tracking-widest w-28 md:w-[200px] uppercase text-center  cursor-pointer hover:text-opacity-100 duration-100 ease delay-0
                  ${index === contentIndex
                    ? theme === "dark" ? " border-b-[3px] border-ProjectBlue text-white" : " border-b-[3px] border-ProjectBlue text-dark"
                    : theme === "dark" ? " border-b-[1px] text-white text-opacity-50 border-[#FFFFFF] border-opacity-15 pb-[2px]" : " border-b-[1px] text-dark text-opacity-50 border-[#00000]  border-opacity-15 pb-[2px]"
                  }`
                }
                key={`content-tab-${index}`}
                id={index}
                onClick={() => setContentIndex(index)}
              >
                {tab.contentBlockTagline}
              </button>
            </>
          ))}
        </div>
      )}
      <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto ">
        <div
          className={`lg:min-h-[537px] flex flex-col items-center justify-center w-full mx-auto my-auto  md:flex-row translate-y-[10px] 
          ${alignment === "left"
              ? "  md:justify-start" 
              : "  md:justify-end"
            }
          `}
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="0"
          data-sal-easing="ease-animationSection"
        >
          {content && content[contentIndex] && (
            <>
              <div
                className={` w-full md:w-1/2 
                ${alignment ==="right" ? " md:ml-8 md:order-1": " md:mr-8 md:order-0 pt-[50px] md:pb-[50px]"}`
                }
              >
                <p
                  className="text-sm md:text-base font-medium leading-5 tracking-widest uppercase text-ProjectBlue Figtree-Medium"
                  data-sal="slide-up"
                  data-sal-duration="1200"
                  data-sal-delay="0"
                  data-sal-easing="ease-animationSection"
                >
                  {content[contentIndex].contentBlockTagline}
                </p>
                <h1
                  className={`mt-5 text-[19px] md:text-4xl font-normal text-${theme === "dark" ? "white" : "dark"
                    } Saira-Bold`}
                  data-sal="slide-up"
                  data-sal-duration="1200"
                  data-sal-delay="50"
                  data-sal-easing="ease-animationSection"
                >
                  {content[contentIndex].contentBlockTitle}
                </h1>
                {renderRichText(content[contentIndex].contentBlockDescription, options)}
                {((content[contentIndex].actionText && content[contentIndex].actionUrl) || sectionButtons ) &&
                  (                
                    <div
                      className="my-0 inline-flex"
                      data-sal="slide-up"
                      data-sal-duration="1200"
                      data-sal-delay="150"
                      data-sal-easing="ease-animationSection"
                    >
                      {content[contentIndex].actionText && content[contentIndex].actionUrl && (
                        <Text
                          link={content[contentIndex]?.actionUrl}
                          title={content[contentIndex]?.actionText}
                          theme={ theme }
                        />
                      )}
                      {sectionButtons && sectionButtons.map((buttonData, index) => (
                        <div key={`sectionButtons-${index}`} className={index === sectionButtons.length - 1 && index > 0 ? "ml-[1.25rem]" : " "}>
                          <Button
                            link={buttonData?.url}
                            title={buttonData.title}
                            variant={buttonData.elementType === "primaryButton" ? "primary" : "secondary"}
                            theme={buttonData?.theme?.[0].toLowerCase()}
                          />
                        </div>
                      ))}
                    </div>
                  )
                }

              </div>
              {content[contentIndex]?.contentGraphic && (
                <div className={`flex items-center w-full h-full md:w-1/2 md:mt-0 lg:my-[0]`}>
                  <Swiper
                    modules={[EffectFade]}
                    slidesPerView={1}
                    className="h-[335px] md:w-[450px] md:h-[450px] flex flex-col items-center justify-center mySwiper"
                    speed={2000}
                    ref={swiperRef}
                    onSlideChange={index => {
                      setImageIndex(index.snapIndex)
                    }}
                    effect="fade"
                    fadeEffect={{
                      crossFade: true,
                    }}
                    simulateTouch={false}
                  >
                    <div className="w-full flex-1  mx-auto ">
                      {content[contentIndex]?.contentGraphic?.map((item, id) => (
                        <SwiperSlide key={id}>
                          <img
                            src={item.image.file.url}
                            alt={item.image.title}
                            className="object-contain object-center w-full h-full overflow-hidden"
                            key={`swiperSide-${id}`}
                          />
                        </SwiperSlide>
                      ))}
                    </div>
                    {content[contentIndex]?.contentGraphic?.length > 1 && (
                      <div className="my-10 flex items-center justify-center w-full mx-auto ">

                        {content[contentIndex]?.contentGraphic?.length > 1 && content[contentIndex]?.contentGraphic?.map((item, id) => (
                          <button
                            className=
                            {` tabs_btn min-h-[70px] md:min-h-[46px] Figtree-Medium font-medium text-[11px] md:text-xs tracking-widest w-28 md:w-[149px] uppercase text-center  cursor-pointer duration-100 ease delay-0  hover:text-opacity-100
                                  ${id === imageIndex
                                ? theme === "dark" ? " border-b-[3px] border-ProjectBlue text-white" : " border-b-[3px] border-ProjectBlue text-dark"
                                : theme === "dark" ? " border-b-[1px] text-white  text-opacity-50 border-[#FFFFFF] border-opacity-15 pb-[2px]" : " border-b-[1px] text-dark  text-opacity-50 border-[#00000]  border-opacity-15 pb-[2px]"
                              }
                                `}
                            key={`content-graphic-${id}`}
                            onClick={() => {
                              swiperRef.current.swiper.slideTo(
                                content[contentIndex]?.contentGraphic?.indexOf(item)
                              )
                            }}
                          >
                            {item.title}
                          </button>
                        ))}
                      </div>
                    )}


                  </Swiper>
                </div>
              )}

            </>
          )}
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  theme: PropTypes.string,
  alignment: PropTypes.string,
  content: PropTypes.array,
}

export default ProductCard
