import React from "react"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Legal = ({ legalData }) => {

  const { title, lastUpdatedDate, tagline, text } = legalData
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h4 className="heading5">{children}</h4>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h4 className="heading6">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="" />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-2xl font-normal leading-6">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul>{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol>{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
      <li>{children}</li>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold  leading-6">{text}</b>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>
    },
  }

  return (
    <>
      <div className="w-screen px-5 md:px-10 xl1:px-40 z-20 bg-black">
        <div className="w-full max-w-screen-xl mx-auto pt-[109px] pb-[79px] flex flex-col items-baseline">
          <p className="text-base text-ProjectBlue leading-6 font-semibold Figtree-SemiBold uppercase tracking-widest">{tagline}</p>
          <h1 className="text-white tracking-subtitle text-[28px] md:text-[56px] leading-lineheight120 Saira-ExtraBold font-normal tracking-atswide uppercase">
            {title}
          </h1>
          <p className="text-[21px] text-white font-workSans font-normal  leading-normal">
            {lastUpdatedDate}
          </p>
        </div>
      </div>
      <div className="w-screen px-5 md:px-10 xl1:px-40 z-20 bg-white">
        <div className="w-full max-w-screen-xl mx-auto py-10 md:py-[105px] flex flex-col items-baseline legal">
          {renderRichText(text, options)}
        </div>
      </div>
    </>

  )

}

export default Legal


