import React from "react"

const Benefits = ({ variant, benefit1, benefit2, benefit3 }) => {
  return (
    <div
      className={` relative w-screen px-5 md:px-10 xl1:px-40 ${
        variant === "dark"
          ? "bg-black text-ProjectBlack"
          : " bg-ProjectBlack text-black"
      }`}
    >
      <div className="w-full  mx-auto md:max-w-screen-xl">
        {/* Section One Benefit Items */}
        <div 
          className="flex flex-wrap items-center flex-col md:flex-row md:justify-between gap-y-[30px]  translate-y-[10px] pb-16 md:pb-20"
          data-sal = "slide-up"
          data-sal-duration="1200"
          data-sal-delay="150"
          data-sal-easing="ease-animationSection"
        >
          <div className=" flex items-center ">
            <div className="h-[90px] w-[2px] border-l-[2px] border-ProjectBlue bg-ProjectBlue"></div>
            <p
              className={`max-w-[336px] text-[17px] tracking-atswide Saira-Regular font-normal leading-normal md:text-[18px] ml-[15px] ${
                variant === "dark" ? "text-ProjectBlack" : "text-black"
              }`}
              data-sal="slide-up"
              data-sal-duration="1200"
              data-sal-delay="0"
              data-sal-easing="ease-animationSection"
            >
              <span className="Saira-Bold tracking-atswide">{benefit1.split(" ")[0]}</span>{" "}
              {benefit1.substring(benefit1.indexOf(" ") + 1)}
            </p>
          </div>
          <div 
            className=" flex items-center "
          >
            <div className="h-[90px] w-[2px] border-l-[2px] border-ProjectBlue bg-ProjectBlue"></div>
            <p
              className={`max-w-[336px] text-[17px] tracking-atswide Saira-Regular font-normal leading-normal md:text-[18px] ml-[15px] ${
                variant === "dark" ? "text-ProjectBlack" : "text-black"
              }`}
              data-sal="slide-up"
              data-sal-duration="1200"
              data-sal-delay="150"
              data-sal-easing="ease-animationSection"
            >
              <span className="Saira-Bold tracking-atswide">{benefit2.split(" ")[0]}</span>{" "}
              {benefit2.substring(benefit2.indexOf(" ") + 1)}
            </p>
          </div>
          <div className=" flex items-center ">
            <div className="h-[90px] w-[2px] border-l-[2px] border-ProjectBlue bg-ProjectBlue"></div>
            <p
              className={`max-w-[336px] text-[17px] tracking-atswide Saira-Regular font-normal leading-normal md:text-[18px] ml-[15px] ${
                variant === "dark" ? "text-ProjectBlack" : "text-black"
              }`}
              data-sal="slide-up"
              data-sal-duration="1200"
              data-sal-delay="300"
              data-sal-easing="ease-animationSection"
            >
              <span className="Saira-Bold tracking-atswide">{benefit3.split(" ")[0]}</span>{" "}
              {benefit3.substring(benefit3.indexOf(" ") + 1)}
            </p>
          </div>
        </div>

        {/* Section Two Image */}
        {/* <div 
          className = "translate-y-[10px]" 
          data-sal="slide-up"                         
          data-sal-duration="1200"
          data-sal-delay="500" 
          data-sal-easing="ease-animationSection"
        >
          <img
            src={require("../../assets/Animation.png").default}
            alt="Animation"
            className="hidden md:block"
          />
        </div> */}
      </div>
    </div>
  )
}

export default Benefits
