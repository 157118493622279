import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const SectionHeader = ({ SectionHeaderData }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p className="mb-[43px] md:mb-[70px] text-base md:text-[19px] font-workSans md:max-w-[600px] mx-auto font-normal leading-lineheight140 md:leading-6">
            {children}
          </p>
        )
      },
    },
  }
  return (
    <div
      className={`w-screen flex items-center justify-center mx-auto md:px-10 xl1:px-32 ${
        SectionHeaderData.theme[0].toLowerCase() === "dark"
          ? "bg-dark text-grey "
          : SectionHeaderData.theme[0].toLowerCase() === "grey" ? "bg-grey text-black"
          : "bg-white text-black"
      }`}
    >
      <div className="w-full max-w-screen-xl mx-auto">
        <div
          className={`flex items-center w-full px-3  ${
            SectionHeaderData.alignment[0].toLowerCase() === "center"
              ? "justify-center"
              : "justify-start"
          }`}
        >
          <div 
            className="text-center translate-y-[10px]"
            data-sal="slide-up" 
            data-sal-duration="1200"
            data-sal-delay="150" 
            data-sal-easing="ease-animationSection"
          >
            <p className=" text-base font-medium leading-5 tracking-wider uppercase text-ProjectBlue Figtree-Medium mt-10 md:mt-20">
              {SectionHeaderData.tagline}
            </p>
            <h1 className="mt-[25px] mb-[20px] text-2xl md:text-[28px] lg:text-[44px] font-normal Saira-Bold max-w-[720px] leading-[120%]">
              {SectionHeaderData.title}
            </h1>
            {typeof window !== "undefined" ? renderRichText(SectionHeaderData.description, options) : ""}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionHeader
