import React from "react"
import BlogCard from "../../components/blog-card"
import ResourceCard from "../../components/resourceCard"
import Button from "../../components/button"

const WhatsNew = ({ featuredData, theme, title }) => {
  const button = featuredData.button
  const variant = button?.elementType === "primaryButton" ? "primary" : "secondary"
  const articlesResources = featuredData.articlesresources
  const layout = (featuredData.layout && featuredData.layout.length) ? featuredData.layout[0] : 'Horizontal'

  return (
    <div
      className={`w-screen mx-auto md:pb-20 translate-y-[0px] px-5 md:px-10 xl1:px-40 ${theme === "dark"
        ? "bg-black text-ProjectBlack"
        : "bg-white text-black"
        }`}
    >
      <div className="w-full mx-auto max-w-screen-xl">
        <div className="flex items-center justify-between py-20">
          <p className={`Saira-Bold font-normal text-[28px] md:text-5xl leading-[120%] ${theme === "dark"
            ? "bg-black text-ProjectBlack"
            : "bg-white text-black"
            }`} >
            {title}
          </p>
          {button && <Button
            link={button?.url}
            title={button?.title}
            variant={variant}
            theme={button?.theme?.[0].toLowerCase()}
          />}
        </div>
        <div className={`flex gap-[30px] ${layout === 'Vertical' ? 'flex-col' : 'flex-col md:flex-row'}`}>
          {articlesResources.map((item, index) => {
            if (Array.isArray(item.tags) && item?.tags.length > 0)
              return (
                <BlogCard
                  title={item?.tags?.[0]}
                  heading={item?.articleTitle}
                  src={item?.articleBannerImage?.file?.url}
                  link={item?.articleTitle.replace(/\s+/g, '-').toLowerCase()}
                  layout={layout}
                  synopsis={item?.synopsis}
                  key={`article-${index}`}
                  theme={theme}
                />
              )
            if (item.resourceTag !== null && item.resourceTag !== "")
              return (
                <ResourceCard
                  title={item?.resourceTag}
                  heading={item?.title}
                  src={item?.resourceGraphic?.file?.url}
                  link={item?.title.replace(/\s+/g, '-').toLowerCase()}
                  layout={layout}
                  synopsis={item?.synopsis}
                  key={`resource-${index}`}
                  actionText={item?.actionText}
                  theme={theme}
                />
              )
            else
              return null
          }
          )}
        </div>
      </div>
    </div>
  )
}

export default WhatsNew
