import React, { useEffect, useContext } from "react"
import Seo from "../../components/seo"
import Notice from "../../components/notice"
import Navbar from "../../components/navbar"
import Hero from "../../components/hero"
import Section from "../../components/section"
import Footer from "../../components/footer"
import SectionHeader from "../../components/sectionHeader"
import CustomersSection from "../../components/customersSection"
import CallToAction from "../../components/callToAction"
import Benefits from "../../components/benefits"
import Form from "../../components/hubspotForm/"
import Legal from "../../components/legal"
import WhatsNew from "../../components/whatsNew"
import CookieConsent from "../../components/cookieConsent"
import utmContext from "../../contexts/utmProvider"
import cookieContext from "../../contexts/cookieProvider"
import _ from 'lodash';

const PageTemplate = (props) => {
	const nodes = props.pageContext.node
	const location = props.location
	const searchParam = location.search
	const searchParamFilter = searchParam.replaceAll("?", "")
	const hubspotData = {}
	// const formFieldGroups = props.pageContext?.form?.data?.hubspotForm?.formFieldGroups || [];
	const utmInfoArray = searchParamFilter.split('&')

	const { utm, addUtm } = useContext(utmContext);
	const { bannerHidden } = React.useContext(cookieContext);

	if (props.pageContext?.form?.data?.allHubspotDataCustom?.nodes) {
		Object.assign(hubspotData, props.pageContext.form.data.allHubspotDataCustom?.nodes?.[0])
	}
	const pageData = nodes.sections
	const title = nodes?.metaTitle
	const metaDescription = nodes?.metaDescription
	const ogImage = nodes?.ogImage
	const imageUrl = ogImage?.file?.url
	const url = nodes?.slug

	useEffect(() => {
		const utmKeyArrays = ["utm_content", "utm_campaign", "utm_medium", "utm_source", "utm_term"]
		let utmData = {};
		if (utmInfoArray) {
			utmInfoArray.forEach(item => {
				const utmInfoKey = item.split("=")[0]
				if (utmKeyArrays.includes(utmInfoKey)) {
					utmData[utmInfoKey] = item.split("=")[1]
				}
			})
			if (!_.isEqual(utm, utmData)) {
				addUtm(utmData);
			}
		}
	}, [utmInfoArray, utm])

	return (
		<>
			<Seo
				title={title}
				metaDescription={metaDescription}
				imageUrl={imageUrl}
				url={url}
			/>
			{
				pageData.map((item, i) => {
					switch (item.elementType) {
						case 'sectionHeader':
							return (
								<SectionHeader key={`${item?.elementType} - ${i}`} SectionHeaderData={item} />
							)
						case 'notice':
							return (
								<Notice key={`${item?.elementType} - ${i}`} NoticeData={item} />
							)
						case 'header':
							return (
								<Navbar key={`${item?.elementType} - ${i}`} NavbarData={item} params={searchParam} />
							)
						case 'hero':
							return (
								<Hero key={`${item?.elementType} - ${i}`} HeroData={item} />
							)
						case 'section':
							return (
								<Section key={`${item?.elementType} - ${i}`} sectionData={item} />
							)
						case 'featured_resource':
							return (
								<WhatsNew key={`${item?.elementType} - ${i}`} theme={item.theme[0].toLowerCase()} title={item?.title} featuredData={item} />
							)
						case 'customersSection':
							return (
								<CustomersSection key={`${item?.elementType} - ${i}`} customersSectionData={item} />
							)

						case 'footer':
							return (
								<Footer key={`${item?.elementType} - ${i}`} variant="dark" footerData={item?.footerColumns} atlasOneLogo={item?.atlasOneLogo} />
							)
						case 'call-to-action':
							return (
								<CallToAction key={`${item?.elementType} - ${i}`} CalltoActionData={item} />
							)
						case 'benefits':
							return (
								<Benefits
									key={`${item?.elementType} - ${i}`}
									variant={item.theme[0].toLowerCase()}
									benefit1={item.benefit1}
									benefit2={item.benefit2}
									benefit3={item.benefit3}
								/>
							)
						case 'form':
							return (
								<Form
									key={`${item?.elementType} - ${i}`}
									FormData={item}
									hubspotData={hubspotData}
								/>
							)
						case 'legal':
							return (
								<Legal key={`${item?.elementType} - ${i}`} legalData={item} />
							)
						case 'featured':
							return (
								<WhatsNew key={`${item?.elementType} - ${i}`} featuredData={item} />
							)
						default:
							return null;
					}
				})
			}
			{!bannerHidden && (
				<CookieConsent />
			)}
		</>
	)
}

export default PageTemplate